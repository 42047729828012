import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LanguageProvider, useLanguage } from './contexts/LanguageContext';// Import LanguageProvider

// Import LanguageProvider
const HomeOne = lazy(() => import("./home/HomeOne"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Produit = lazy(() => import("./product/Product"));
const NoMAtch = lazy(() => import("./pages/404"));
const MagneticEquipment = lazy(() => import("./product/MagneticEquipment"));
const TurnkeySystems = lazy(() => import("./product/TurnkeySystems"));
const CetD = lazy(() => import("./product/Turnkey_Systems_CandD"));
const Spotlight = lazy(() => import("./Talking_About_Us/Talking_About_Us"));
const Events = lazy(() => import("../src/blog/BlogLeftSidebar"));

const App = () => {
  

  return (
    <LanguageProvider> {/* Wrap everything with LanguageProvider */}

    <Router>

        <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        <Suspense fallback={<div />}>
            <Routes>
                <Route path="/" element={<HomeOne />} />
                <Route path="/accueil" element={<HomeOne />} />
                <Route path="/a-propos" element={<About />} />
                <Route path="/produit" element={<Produit />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/magneticEquipment" element={<MagneticEquipment />} />
                <Route path="/turnkeySystems" element={<TurnkeySystems />} />
                <Route path="/CetD" element={<CetD />} />
                <Route path="/talking_about_us" element={<Spotlight />} />
                <Route path="/events" element={<Events />} />

                <Route path="*" element={<NoMAtch />} />
            </Routes>
        </Suspense>
      </Router>
      </LanguageProvider>

  )
}

export default App